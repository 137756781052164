import barba from '@barba/core';
import anime from 'animejs/lib/anime.es';

import { heroAnimation } from './hero';
import { hamburgerMenu } from './hamburger-menu';
import { mainHeader } from './main-header';
import { MAIN_HEADER } from '../constants';

const MH_ID = MAIN_HEADER.mainHeader.id;

export function init() {
    barba.init({
        transitions: [
            {
                name: 'default-transition',
                async leave() {
                    anime({
                        targets: `${MH_ID}`,
                        translateY: [0, '-100%'],
                        duration: 1000,
                        easing: 'easeInOutQuad'
                    }).finished;

                    anime({
                        targets: '#js-main',
                        translateX: [0, '-200px'],
                        duration: 1000,
                        easing: 'easeInOutQuad'
                    });

                    await anime({
                        targets: '#js-cover',
                        left: ['100%', '0'],
                        duration: 750,
                        easing: 'easeInOutQuad'
                    }).finished;

                    window.scrollTo(0, 0);

                    // create your stunning leave animation here
                },
                enter() {
                    anime({
                        targets: '#js-cover',
                        left: ['0', '-100%'],
                        duration: 350,
                        easing: 'easeInOutQuad'
                    });

                    anime({
                        targets: '#js-main',
                        translateX: ['200px', '0'],
                        duration: 350,
                        easing: 'easeInOutQuad'
                    });

                    heroAnimation();
                    setTimeout(() => {
                        hamburgerMenu();
                        mainHeader();
                        document.getElementById('js-main').style.transform =
                            'none';
                    }, 500);
                }
            }
        ]
    });
}
