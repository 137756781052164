import anime from 'animejs';
import { HERO } from '../constants';

const { hero, scroll } = HERO;

export function heroAnimation() {
    anime({
        targets: '.js-hero-stage-headline .js-hero-stage-text',
        opacity: [0, 1],
        translateX: ['-50%', 0],
        duration: 800,
        delay: anime.stagger(100),
        easing: 'easeInOutQuad'
    });

    anime({
        targets: '#js-hero-img',
        scale: [1.2, 1],
        duration: 1500,
        easing: 'easeInOutQuad'
    });

    anime({
        targets: '.js-hero-particles',
        opacity: [0, 1],
        translateX: ['-50%', 0],
        duration: 500,
        delay: 750,
        easing: 'easeInOutQuad'
    });
}

export function heroScroll() {
    const heroScroll = document.getElementById(scroll.id);

    if (document.getElementById(hero.id)) {
        const { height } = document
            .getElementById(hero.id)
            .getBoundingClientRect();

        heroScroll.addEventListener('click', () =>
            window.scrollTo({ top: height })
        );
    }
}
