import anime from 'animejs/lib/anime.es';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { MAIN_HEADER } from '../constants';
import { flyout, flyoutInit } from './flyout';

const LOGO_ID = MAIN_HEADER.logo.id;
const HAMBURGER_ID = MAIN_HEADER.hamburger.id;
const HAMBURGER_CHILD_CLASS = MAIN_HEADER.hamburger.childsClass;

function animateButton(target, translateX, translateY, rotate, width) {
    anime.remove(target);
    anime({
        targets: target,
        translateX,
        translateY,
        rotate,
        duration: 200,
        easing: 'easeInOutQuad'
    });
}

export const hamburgerMenu = () => {
    // global state to toggle and target menu state
    let stateIsOpen = false;
    const logo = document.getElementById(LOGO_ID);
    const hamburgerIcon = document.getElementById(HAMBURGER_ID);
    const hamburgerIconLineOne = `#${HAMBURGER_ID} .${HAMBURGER_CHILD_CLASS}-1`;
    const hamburgerIconLineTwo = `#${HAMBURGER_ID} .${HAMBURGER_CHILD_CLASS}-2`;
    const hamburgerIconLineThree = `#${HAMBURGER_ID} .${HAMBURGER_CHILD_CLASS}-3`;

    flyoutInit();

    // hamburger menu hover enter
    hamburgerIcon.addEventListener('mouseenter', (e) => {
        if (!stateIsOpen) {
            animateButton(hamburgerIconLineOne, 0, 25, -15);
            animateButton(hamburgerIconLineTwo, 0, 6, 5);
            animateButton(hamburgerIconLineThree, 0, -10, -5);
        }
    });

    // hamburger menu hover leave
    hamburgerIcon.addEventListener('mouseleave', (e) => {
        if (!stateIsOpen) {
            animateButton(hamburgerIconLineOne, 0, 0, 0);
            animateButton(hamburgerIconLineTwo, 0, 0, 0);
            animateButton(hamburgerIconLineThree, 0, 0, 0);
        }
    });

    // hamburger menu hover click
    hamburgerIcon.addEventListener('click', (e) => {
        if (!stateIsOpen) {
            stateIsOpen = true;
            logo.classList.add('logo--white');
            hamburgerIcon.classList.add('hamburger--white');
            //disableBodyScroll('body');

            animateButton(hamburgerIconLineOne, 10, 75, -45);
            animateButton(hamburgerIconLineTwo, 50, -25, 45);
            animateButton(hamburgerIconLineThree, 0, 0, 0);

            flyout(stateIsOpen);
        } else if (stateIsOpen) {
            stateIsOpen = false;
            logo.classList.remove('logo--white');
            hamburgerIcon.classList.remove('hamburger--white');
            //enableBodyScroll('body');

            animateButton(hamburgerIconLineOne, 0, 0, 0);
            animateButton(hamburgerIconLineTwo, 0, 0, 0);
            animateButton(hamburgerIconLineThree, 0, 0, 0);

            flyout(stateIsOpen);
        }
    });
};
