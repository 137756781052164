import { MAIN_HEADER, SCROLLER } from '../constants';

const MH_ID = MAIN_HEADER.mainHeader.id;
const LOGO_ID = MAIN_HEADER.logo.id;
const HAMBURGER_ID = MAIN_HEADER.hamburger.id;
const SCROLLER_ID = SCROLLER.id;

export function mainHeader() {
    const mainH = document.getElementById(MH_ID);
    const mainHData = mainH.getAttribute('data-logo-color');
    const mainHModifier =
        mainHData === 'white' ? 'main-header--dark' : 'main-header--light';
    const hamburger = document.getElementById(HAMBURGER_ID);
    const logo = document.getElementById(LOGO_ID);
    const scroller = document.getElementById(SCROLLER_ID);
    let pageHeight = document.body.scrollHeight;

    scroller.classList.add('scroller--is-hidden');

    window.addEventListener('resize', () => {
        pageHeight = document.body.scrollHeight;
    });

    window.addEventListener('scroll', (e) => {
        if (window.scrollY > 125) {
            mainH.classList.add(mainHModifier);
            mainH.classList.add('main-header--scrolled');
            logo.classList.add('logo--scrolled');
            hamburger.classList.add('hamburger--scrolled');
        } else {
            mainH.classList.remove(mainHModifier);
            mainH.classList.remove('main-header--scrolled');
            logo.classList.remove('logo--scrolled');
            hamburger.classList.remove('hamburger--scrolled');
        }

        if (window.scrollY > pageHeight - window.innerHeight * 2) {
            scroller.classList.remove('scroller--is-hidden');
        } else {
            if (window.scrollY < pageHeight - window.innerHeight * 2) {
                scroller.classList.add('scroller--is-hidden');
            }
        }
    });

    scroller.addEventListener('click', () => window.scrollTo({ top: 0 }));
}
