import anime from 'animejs/lib/anime.es';
import $ from 'jquery';

function makeNewPos(multiply) {
    const nh = Math.floor(Math.random() * multiply[0]);
    const nw = Math.floor(Math.random() * multiply[1]);

    return [`${nh}%`, `${nw}%`];
}

function animateRect(target, multiply) {
    const cloudRects = $(`${target}`);
    cloudRects.each((idx) => {
        const newq = makeNewPos(multiply);
        anime({
            targets: cloudRects[idx],
            translateX: newq[0],
            translateY: newq[1],
            duration: 1200,
            easing: 'easeInOutQuad'
        });
    });
}

export function Cloud() {
    // if (
    //     window.location.pathname !== '/' ||
    //     window.location.pathname !== '/preview/'
    // )
    //     return;

    $(function () {
        const cloudRects = $('#cloud-graphic .cloud__particle');
        cloudRects.each((idx) => {
            const op = (Math.floor(Math.random() * 15) + 1) / 30;
            anime({
                targets: cloudRects[idx],
                transformX: [-10, 0],
                scale: [1.1, 1],
                duration: 1000,
                delay: 10 * idx,
                easing: 'easeInOutQuad'
            });
        });
        const cloudIcons = $('#cloud-graphic .cloud__icon');
        cloudIcons.each((idx) => {
            anime({
                targets: cloudIcons[idx],
                opacity: [0, 1],
                transformX: [-10, 0],
                scale: [1.1, 1],
                duration: 1000,
                delay: 10 * idx,
                easing: 'easeInOutQuad'
            });
        });
        setTimeout(() => {
            animateRect('#cloud-graphic .cloud__particle', [100, 80]);
            animateRect('#cloud-graphic .cloud__icon', [25, 40]);
            setInterval(() => {
                animateRect('#cloud-graphic .cloud__particle', [100, 80]);
                animateRect('#cloud-graphic .cloud__icon', [25, 40]);
            }, 1199);
        }, 1400);
    });
}
