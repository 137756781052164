import { init } from './components/barba';
import $ from 'jquery';
import anime from 'animejs/lib/anime.es';
import { mainHeader } from './components/main-header';
import { hamburgerMenu } from './components/hamburger-menu';
import { heroAnimation, heroScroll } from './components/hero';
import {
    revealOnScroll,
    checkIfIsInViewport
} from './components/revealOnScroll';
import { Cloud } from './components/cloud';

setTimeout(() => {
    hamburgerMenu();
}, 500);

mainHeader();
heroAnimation();
heroScroll();
revealOnScroll();
Cloud();

$(function () {
    if (
        window.location.pathname !== '/' ||
        window.location.pathname === '/preview/'
    )
        return;
    const heros = $('.js-hero-lane').find('.hero');
    let group = [];
    heros.each((index) => {
        index > 3 && group.push(heros[index]);
    });
    $(group).wrapAll('<div class="hero__accordeon hero--hidden"/>');
    $(
        '.hero--hidden'
    ).prepend(`<div id="js-hero-scroll" class="hero--inline-scroll">
  <span class="text fnt-utl-wei--500 fnt-utl-trn--uppercase fnt-utl-al--center">Mehr Produkte zeigen</span>

      <svg class="icon hero__scroll__arrow hero__scroll__arrow--clr-normal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.32 30.41">
          <polygon points="2.12 30.41 0 28.29 13.08 15.2 0 2.12 2.12 0 17.32 15.2 2.12 30.41"></polygon>
      </svg>

</div>`);

    $('.hero--hidden').on('click', function () {
        $('.hero--inline-scroll').remove();
        !$('.hero--hidden').hasClass('isShown');
        $('.hero--hidden').addClass('isShown');
        $('.hero--hidden').css({ height: 'auto' });
        $('.hero__accordeon').removeClass('hero--hidden');
    });
});

$('.js-text-link').on('click', function (t) {
    const target = $(this).data('link');
    window.open(`${target}`, '_blank');
});

if (
    window.location.pathname === '/' ||
    window.location.pathname === '/preview/'
) {
    const blockQuote = $('.blockquote')[0];
    $(blockQuote).addClass('should-animate');
    const blockQuoteChildren = [];
    blockQuoteChildren.push($(blockQuote).find('.blockquote__quote')[0]);
    blockQuoteChildren.push($(blockQuote).find('.blockquote__cite')[0]);

    $(blockQuoteChildren).each((index) => {
        $(blockQuoteChildren[index]).css({ opacity: 0 });
    });

    const teaserIconText = $('.teaser-icon-text')[0];
    $(teaserIconText).addClass('should-animate');
    const teaserIconTextLists = $(teaserIconText).find('li');

    $(teaserIconTextLists).each((index) => {
        const headline = $(teaserIconTextLists[index]).find(
            '.teaser-icon-text__headline'
        )[0];
        const icon = $(teaserIconTextLists[index]).find(
            '.teaser-icon-text__icon'
        )[0];
        const body = $(teaserIconTextLists[index]).find(
            '.teaser-icon-text__body'
        )[0];

        $(headline).css({ opacity: 0 });
        $(body).css({ opacity: 0 });
        $(icon).css({ opacity: 0 });
    });

    window.addEventListener('scroll', function () {
        if (
            checkIfIsInViewport(blockQuoteChildren[1]) &&
            $(blockQuote).hasClass('should-animate')
        ) {
            $(blockQuoteChildren).each((index) => {
                anime({
                    targets: blockQuoteChildren[index],
                    opacity: [0, 1],
                    translateY: [40, 0],
                    duration: 500,
                    delay: 750 * (index + 1),
                    easing: 'easeInOutQuad'
                });
            });
            $(blockQuote).removeClass('should-animate');
        }

        if (
            checkIfIsInViewport(teaserIconTextLists[0]) &&
            $(teaserIconText).hasClass('should-animate')
        ) {
            teaserIconTextLists.each((index) => {
                const headline = $(teaserIconTextLists[index]).find(
                    '.teaser-icon-text__headline'
                )[0];
                const icon = $(teaserIconTextLists[index]).find(
                    '.teaser-icon-text__icon'
                )[0];
                const body = $(teaserIconTextLists[index]).find(
                    '.teaser-icon-text__body'
                )[0];

                anime({
                    targets: icon,
                    opacity: [0, 1],
                    translateY: [40, 0],
                    duration: 500,
                    delay: 500 * (index + 1),
                    easing: 'easeInOutQuad'
                });
                anime({
                    targets: headline,
                    opacity: [0, 1],
                    translateY: [40, 0],
                    duration: 500,
                    delay: 750 * (index + 1),
                    easing: 'easeInOutQuad'
                });
                anime({
                    targets: body,
                    opacity: [0, 1],
                    translateY: [40, 0],
                    duration: 500,
                    delay: 1000 * (index + 1),
                    easing: 'easeInOutQuad'
                });
            });
            $(teaserIconText).removeClass('should-animate');
        }
    });
}
//init();
