import anime from 'animejs/lib/anime.es';
import $ from 'jquery';

const ANIM_DURATION = 600;

export function checkIfIsInViewport(target) {
    const positionFromTop = target.getBoundingClientRect().top;
    return positionFromTop - window.innerHeight <= 0 ? true : false;
}

function animate(targetElement, delay) {
    anime({
        targets: targetElement,
        opacity: [0, 1],
        translateY: [40, 0],
        duration: ANIM_DURATION,
        delay: 350 * delay,
        easing: 'easeInOutQuad'
    });
}

function animateImg(targetElement, delay) {
    anime({
        targets: targetElement,
        opacity: [0, 1],
        scale: [1.3, 1],
        duration: ANIM_DURATION * 2,
        delay: 350 * delay,
        easing: 'easeInOutQuad'
    });
}

function orchestrateAnimation(target) {
    const targetElement = $(target)[0];
    if ($(targetElement).hasClass('hero--should-animate')) {
        const heroMeta = $(targetElement).find('.hero__meta')[0];
        const heroHeadline = $(targetElement).find('.hero__headline')[0];
        const heroSubline = $(targetElement).find('.hero__subline')[0];
        const heroLink = $(targetElement).find('a.link')[0];
        const heroImg = $(targetElement).find('.hero__img')[0];

        animateImg(heroImg, 0.75);
        animate(heroMeta, 1);
        animate(heroHeadline, 2);
        animate(heroSubline, 2.5);
        animate(heroLink, 3);
        $(targetElement).removeClass('hero--should-animate');
    }
}

export function revealOnScroll() {
    let elements = [];
    let windowHeight;

    function init() {
        if (
            window.location.pathname === '/' ||
            window.location.pathname === '/preview/'
        ) {
            const heros = $('.hero');
            heros.each((index) => {
                index !== 0 && $(heros[index]).addClass('hero--should-animate');
            });
            elements = $('.hero--should-animate');
            windowHeight = window.innerHeight;
        }
    }

    function checkPosition() {
        if (
            window.location.pathname === '/' ||
            window.location.pathname === '/preview/'
        ) {
            for (var i = 0; i < elements.length; i++) {
                const element = elements[i];
                const elementHero = $(element).find('.hero__subline')[0];
                const positionFromTop = elementHero.getBoundingClientRect().top;

                if (positionFromTop - windowHeight <= 0) {
                    orchestrateAnimation(element);
                }
            }
        }
    }

    window.addEventListener('scroll', checkPosition);
    window.addEventListener('resize', init);

    $(function () {
        init();
        checkPosition();
    });
}
