import anime from 'animejs/lib/anime.es';
import { MAIN_HEADER } from '../constants';
import $ from 'jquery';

const CONTENT_ID = '#js-content';
const FLYOUT_ID = `#${MAIN_HEADER.flyout.id}`;
const NAV_ID = `#${MAIN_HEADER.nav.id}`;
const NAV_ITEMS_CLASS = `.${MAIN_HEADER.nav.itemsClass}`;
const NAV_SECOND_ID = `#${MAIN_HEADER.navSecond.id}`;

function animateFlyout(duration, target, translateX) {
    anime({
        targets: target,
        translateX: translateX,
        duration: duration,
        easing: 'easeInOutQuad'
    });
}

function animateNav(delay, target, translateX) {
    anime({
        targets: target,
        opacity: [0, 1],
        translateX: translateX,
        duration: 350,
        delay: delay,
        easing: 'easeInOutQuad'
    });
}

// Orchestrates flyout and it's nav elements
export const flyout = (isOpen) => {
    if (isOpen) {
        animateFlyout(350, CONTENT_ID, '-10%');
        animateFlyout(750, FLYOUT_ID, 0);

        animateNav(150, NAV_ID, ['50%', 0]);
        animateNav(anime.stagger(100, { start: 25 }), NAV_ITEMS_CLASS, [
            100,
            0
        ]);
        animateNav(800, NAV_SECOND_ID, ['20%', 0]);
    } else {
        animateFlyout(250, CONTENT_ID, 0);
        animateFlyout(500, FLYOUT_ID, '100%');
    }
};

// Sets initial state of flyout to 100%
export const flyoutInit = () => {
    anime.set('#js-main-header-flyout', {
        translateX: '100%'
    });
};
