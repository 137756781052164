export const MAIN_HEADER = {
    mainHeader: {
        id: 'js-main-header'
    },
    logo: {
        id: 'js-logo-main'
    },
    hamburger: {
        id: 'js-btn-hamburger-icon',
        childsClass: 'js-hamburger-line'
    },
    flyout: {
        id: 'js-main-header-flyout'
    },
    nav: {
        id: 'js-main-header-navlist',
        itemsClass: 'js-main-header-navlist-item'
    },
    navSecond: {
        id: 'js-main-header-navlist-second'
    }
};

export const SCROLLER = {
    id: 'js-scroller'
};

export const HERO = {
    hero: {
        id: 'js-hero'
    },
    scroll: {
        id: 'js-hero-scroll'
    }
};
